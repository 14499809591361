import styled, { css } from "styled-components"
import { Link } from "gatsby"
import colors from "../styles/colors"
import devices from "../styles/devices"

import BannerHome from "../assets/banner-home.jpg"

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 370px;
  background: url(${BannerHome});
  background-position: center;
  background-size: cover;
  color: white;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;

  h1,
  p {
    text-shadow: 0 2px 0px rgba(0, 0, 0, 0.35);
  }

  @media (max-width: ${devices.mobileL}) {
    text-align: center;
    height: 635px;
  }
`

export const HeroTitle = styled.h1`
  font-size: 48px;
  font-weight: bolder;
`

export const HeroSubtitle = styled.p`
  max-width: 540px;
  text-align: center;
  line-height: 24px;
`

export const Divider = styled.div`
  height: 4px;
  width: 62px;
  background: #fff;
  margin: 16px 0;
`

export const CTA = styled(Link)`
  background: ${colors.orange};
  font-weight: bolder;

  text-decoration: none;
  padding: 12px 28px;
  text-transform: uppercase;

  ${({ negative }) =>
    negative &&
    css`
      background: ${colors.blue};
      color: #fff;
    `}
`

export const Images = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0;

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;
  }
`

export const Image = styled.img`
  height: 320px;
  object-fit: cover;

  @media (max-width: ${devices.mobileL}) {
    + img {
      margin-top: 20px;
    }
  }
`

export const Products = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    margin-top: 10px;
  }
`
export const Quality = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;

  img {
    padding: 32px 0;
  }
`

export const QualityItem = styled.div`
  @media (max-width: ${devices.mobileL}) {
    margin-top: 40px;
  }
`

export const QualityItems = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;
    padding: 0 40px;
  }
`
export const More = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-transform: uppercase;
  }

  a {
    margin-top: 12px;
    color: #fff;
  }
`
