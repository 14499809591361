import React from "react"

import DefaultLayout from "../layouts/default"

import Content from "../components/Content"
import Description from "../components/Description"

import Home1 from "../assets/imagem-01-home.jpg"
import Home2 from "../assets/imagem-02-home.jpg"
import Seta from "../assets/seta.jpg"

import {
  Hero,
  HeroTitle,
  HeroSubtitle,
  Divider,
  CTA,
  Images,
  Image,
  Products,
  Quality,
  QualityItems,
  QualityItem,
  More,
} from "./styles.styled"

export default () => (
  <DefaultLayout>
    <Hero>
      <HeroTitle>Locações inteligentes</HeroTitle>
      <CTA to="/contato">Mande uma mensagem</CTA>
    </Hero>
    <Content>
      <Description centered title="Equipamentos" />
      <Images>
        <Image src={Home1} />
        <Image src={Home2} />
      </Images>
      <Products>
        <p>Veja nossos produtos</p>
        <CTA negative to="/equipamentos">
          Produtos
        </CTA>
      </Products>
      <Quality>
        <Description centered title="Qualidade" />
        <img src={Seta} alt="" />
        <QualityItems>
          <QualityItem>
            <Description centered title="Diversidade">
              Oferecendo sempre os melhores equipamentos para locação
            </Description>
          </QualityItem>
          <QualityItem>
            <Description centered title="Modernidade">
              Máquinas de ponta, sempre atualizadas e renovadas
            </Description>
          </QualityItem>
          <QualityItem>
            <Description centered title="Sustentabilidade">
              Cuidado redobrado com o descarte e impactos ao meio ambiente
            </Description>
          </QualityItem>
        </QualityItems>
      </Quality>
      <More>
        <p>Conheça mais a história da Rentalsef</p>
        <CTA to="/sobre">Sobre</CTA>
      </More>
    </Content>
  </DefaultLayout>
)
